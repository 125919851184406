import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Gapped, Spinner } from '@skbkontur/react-ui';
import { IAppState } from '../../../store/types';
import { drawStickersToCanvas, drawToCanvasFromBase64 } from '../../Canvas/helpers';
import { StickerItem } from './StickerItem';
import { StickerItemName } from './constants';
import { StickerProps } from './types';
import { resetSticker, updateImage, setApplyingStatus as disableMenu } from '../../../store/actions';
import { getCount } from '../helpers';
import { DRAW_DELAY } from '../constants';

export const Sticker: React.FC<StickerProps> = (props: StickerProps) => {
  const { canvas } = props;
  const image = useSelector((state: IAppState) => state.editor.image);
  const stickers = useSelector((state: IAppState) => state.sticker.stickers);
  const [applying, setApplying] = useState(false);
  const isDisable = getCount(stickers) === 0 || applying;

  const dispatch = useDispatch();

  useEffect(() => {
    return (() => {
      dispatch(resetSticker());
      dispatch(disableMenu(false));
    });
  }, []);

  useEffect(() => {
    drawToCanvasFromBase64(canvas, image);
  }, [image]);

  const handleApplyButtonClick = () => {
    setApplying(true);
    dispatch(disableMenu(true));
    drawStickersToCanvas(stickers, canvas);

    setTimeout(() =>
    {
      dispatch(updateImage(canvas.toDataURL()));
      dispatch(resetSticker());
      setApplying(false);
      dispatch(disableMenu(false));
    }, DRAW_DELAY);
  };

  return (
    <Gapped vertical>
      <p>Стикеры</p>
      <div>
        {
          Object.values(StickerItemName).map((sticker) => <StickerItem sticker={sticker} key={sticker} />)
        }
      </div>
      <Button onClick={handleApplyButtonClick} disabled={isDisable}>{applying ? <Spinner type='mini' caption='Применяем' /> : `Применить`}</Button>
    </Gapped>
  );
};
