import React from 'react';
import b_ from 'b_';
import { Tabs, Tooltip } from '@skbkontur/react-ui';
import { TOOLTIP_POS, TOOLTIP_TRIGGER } from './constants';
import { ToolsNavItemProps } from './types';

import '../Layout.css';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/types';

const b = b_.with('layout');

const ToolsNavItem: React.FC<ToolsNavItemProps> = (props: ToolsNavItemProps) => {
  const { id, title } = props;
  const applying = useSelector((state: IAppState) => state.status.applying || false);

  return (
    <Tooltip render={() => title} pos={TOOLTIP_POS} trigger={TOOLTIP_TRIGGER}>
      <Tabs.Tab id={id} className={b('tools-item')} disabled={applying}>
        <img src={`/svg/${id}.svg`} alt={title} />
        <h3 className="hidden">{title}</h3>
      </Tabs.Tab>
    </Tooltip>
  );
};

export default ToolsNavItem;
