import { CollageEditorPresets } from "./CollageEditor.types";

export const collageEditorPresets: CollageEditorPresets = {
    two: [
        [{ x: 0, y: 0, width: 100, height: 50 }, { x: 0, y: 50, width: 100, height: 50 }],
        [{ x: 0, y: 0, width: 50, height: 100 }, { x: 50, y: 0, width: 50, height: 100 }]
    ],
    three: [
        [{x: 0, y: 0, width: 50, height: 50}, {x: 50, y: 0, width: 50, height: 50}, {x: 0, y: 50, width: 100, height: 50}],
        [{x: 0, y: 0, width: 50, height: 100}, {x: 50, y: 0, width: 50, height: 50}, {x: 50, y: 50, width: 50, height: 50}],
        [{x: 0, y: 0, width: 100, height: 50}, {x: 0, y: 50, width: 50, height: 50}, {x: 50, y: 50, width: 50, height: 50}],
        [{x: 0, y: 0, width: 50, height: 50}, {x: 0, y: 50, width: 50, height: 50}, {x: 50, y: 0, width: 50, height: 100}],
    ],
    four: [
        [{x: 0, y: 0, width: 50, height: 50}, {x: 0, y: 50, width: 50, height: 50}, {x: 50, y: 0, width: 50, height: 50}, {x: 50, y: 50, width: 50, height: 50}]
    ],
    five: [],
};