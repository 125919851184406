import React from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Resizable } from 're-resizable';
import b_ from 'b_';
import { deleteSticker, selectSticker, updateSticker } from '../../../store/actions';
import { DraggableItems } from '../types';
import { CanvasStickerItemProps } from './types';
import { emptyPixel } from '../constants';

import './CanvasStickerItem.css';
import { IAppState } from '../../../store/types';

const b = b_.with('canvas-sticker-item');

export const CanvasStickerItem: React.FC<CanvasStickerItemProps> = (props: CanvasStickerItemProps) => {
  const { id, data, top, left, width, height } = props;
  const isCurrent = useSelector<IAppState, boolean>((state) => state.sticker.selectedId === id);

  const dispatch = useDispatch();

  const [, dragRef, preview] = useDrag(() => ({
    type: DraggableItems.Sticker,
    item: { id, top, left, type: DraggableItems.Sticker },
  }), [id, left, top]);

  const handleDeleteStickerClick = () => {
    dispatch(deleteSticker(id));
  };

  const handleStickerClick = () => {
    dispatch(selectSticker(id));
  };

  return (
    <Resizable
      defaultSize={{
        width,
        height,
      }}
      style={{
        position: 'absolute',
        top,
        left,
      }}
      lockAspectRatio
      onResizeStop={(e, direction, ref, d) => {
        const newWidth = width + d.width;
        const newHeight = height + d.height;

        dispatch(updateSticker(id, {width: newWidth, height: newHeight}));
      }}
    >
      <DragPreviewImage connect={preview} src={emptyPixel} />
      <div
        className={b('sticker', {
          active: isCurrent,
        })}
        ref={dragRef}
        onClick={handleStickerClick}
        style={{
          height,
          width,
          background: `url(${data}) no-repeat`,
          backgroundSize: `${width}px ${height}px`,
        }}
      >
        {isCurrent && <button className={b('delete')} onClick={handleDeleteStickerClick}>
          <img src='/svg/close.svg' alt='Delete' width='5' height='5' />  
        </button>}
      </div>
    </Resizable>
  );
}
