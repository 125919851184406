import { Select} from '@skbkontur/react-ui';
import { FrameTemplates } from './templates';

export const DEFAULT_FRAME = 'Без рамки';

export const GlobalCompositeOperation = {
  DESTINATION_IN: 'destination-in',
  SOURCE_OVER: 'source-over',
};

export const IMAGE_SVG_XML = 'image/svg+xml';

export const FRAMES = [DEFAULT_FRAME, Select.SEP, ...Object.entries(FrameTemplates).map(([key, value]) => [key, value.name])];
