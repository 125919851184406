import React from 'react';
import { useDispatch } from 'react-redux';
import FileReaderInput from 'react-file-reader-input';
import { Button } from '@skbkontur/react-ui';
import { setErrorAction, setImageAction, setOriginal } from '../../../store/actions';
import { checkFileTypeImage } from '../utils/check-file-type';
import { getCanvasFromBase64, getScale, scaleCanvas } from '../../Canvas/helpers';
import { DRAW_DELAY } from '../constants';

const GAP = 200;

export const ImageLoader: React.FC = () => {
    const dispatch = useDispatch();

    const onChange = (_: React.ChangeEvent<HTMLInputElement>, results: FileReaderInput.Result[]) => {
        const fileReader = new FileReader();

        fileReader.onload = function(e) {
            const image = e.target.result as string;
            const container = document.querySelector('.layout__canvas-wrapper');
            const source = getCanvasFromBase64(image);

            setTimeout(() => {
              const scaledCanvas = document.createElement('canvas');

              scaledCanvas.width = container.clientWidth - GAP;
              scaledCanvas.height = container.clientHeight - GAP;

              const scale = Math.min(getScale(source.width, scaledCanvas.width), getScale(source.height, scaledCanvas.height));

              scaleCanvas(source, scaledCanvas, scale);

              dispatch(setImageAction(scaledCanvas.toDataURL()));
              dispatch(setOriginal(scaledCanvas.toDataURL()));
            }, DRAW_DELAY);
        };
        const [, file] = results[0];

        if (checkFileTypeImage(file)) {
            fileReader.readAsDataURL(file);
        } else {
            dispatch(setErrorAction({ message: 'Файл не является изображением' }));
        }
    };


    return (
        <FileReaderInput onChange={onChange} >
            <Button>Загрузить изображение</Button>
        </FileReaderInput>
    );
};
