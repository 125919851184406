import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Gapped } from '@skbkontur/react-ui';
import { clearCanvas, drawToCanvasFromBase64 } from '../../Canvas/helpers';
import { applyRotation, setRotationAngleAction } from '../../../store/actions';
import { IAppState } from '../../../store/types';
import { ImageRotatorProps } from './types';

export const ImageRotator: React.FC<ImageRotatorProps> = (props: ImageRotatorProps) => {
  const { canvas } = props;
  const image = useSelector((state: IAppState) => state.editor.image);
  const storeAngle = useSelector((state: IAppState) => state.editor.rotationAngle);
  const [currentAngle, setCurrentAngle] = useState(storeAngle);

  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      drawToCanvasFromBase64(canvas, image);
    }
  }, [image]);

  const handleAngleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const angle = Number(e.target.value) || 0;
    setCurrentAngle(angle);

    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.src = image;

    const imgCenterWidth = img.width / 2;
    const imgCenterHeight = img.height / 2;

    const angleInRadian = angle * Math.PI / 180;

    clearCanvas(canvas);
    ctx.setTransform(1, 0, 0, 1, imgCenterWidth, imgCenterHeight);
    ctx.rotate(angleInRadian);
    ctx.drawImage(img, -imgCenterWidth, -imgCenterHeight);
    ctx.setTransform(1, 0, 0, 1, 0, 0); // Сбросили все преобразования в default
  }

  const handleApplyButtonClick = () => {
    dispatch(applyRotation(canvas.toDataURL()));
    setCurrentAngle(0);
  }

  return (
    <Gapped vertical>
      <label htmlFor="angle">Повернуть</label>
      <input type="range" id="angle" name="angle" min="-360" max="360" onChange={handleAngleChange} value={currentAngle} />
      <input type="number" name="angle" min="-360" max="360" onChange={handleAngleChange} value={currentAngle} />
      <Button onClick={handleApplyButtonClick}>Применить</Button>
    </Gapped>
  );
};
