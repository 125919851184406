import React from 'react';
import b_ from 'b_';

import { CollageEditorPresetProps } from './CollageEditorPreset.types';

import './CollageEditorPreset.css';

const b = b_.with('collage-preset');

export const CollageEditorPreset: React.FC<CollageEditorPresetProps> = ({ preset, onSelect, isActive, images, scalePercent = 0 }) => {
    return (
        <div className={b({ active: isActive })}>
            <div className={b('container')} onClick={() => onSelect(preset)} style={{ width: scalePercent + 100, height: scalePercent + 100 }}>
                {preset.map((block, idx) => {
                    const { x, y, width, height } = block;
                    const indentWithScalePercent = scalePercent + 100;

                    const style: React.CSSProperties = {
                        top: (indentWithScalePercent * y) / 100 ,
                        left: (indentWithScalePercent * x) / 100,
                        width: `${width}%`,
                        height: `${height}%`
                    };
                    if (images && images[idx]) {
                        style.backgroundImage = `url(${images[idx]})`;
                        style.backgroundSize = 'cover';
                    }

                    return <div key={`${block}-${idx}`} className={b('block')} style={style}/>;
                })}
            </div>
        </div>
    );
};
