import { TextState, StickerState } from '../../store/types';

export const clearCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');
  ctx.setTransform(1, 0, 0, 1, 0, 0); // Сбросили все преобразования в default
  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

export const copyCanvas = (from: HTMLCanvasElement, to: HTMLCanvasElement) => {
  to.width = from.width;
  to.height = from.height;

  const toCtx = to.getContext('2d');
  toCtx.drawImage(from, 0, 0);
};

export const getCanvasCopy = (canvas: HTMLCanvasElement) => {
  const canvasCopy = document.createElement('canvas');

  copyCanvas(canvas, canvasCopy);

  return canvasCopy;
};

export const restoreCanvas = (from: HTMLCanvasElement, to: HTMLCanvasElement) => {
  clearCanvas(to);

  const ctx = to.getContext('2d');
  ctx.drawImage(from, 0, 0);
};

export const getCanvasFromBase64 = (base64: string) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const img = new Image();

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);
  }

  img.src = base64;

  return canvas;
};

export const drawToCanvasFromBase64 = (canvas: HTMLCanvasElement, base64: string) => {
  const ctx = canvas.getContext('2d');

  const image = new Image();
  image.src = base64;

  image.onload = () => {
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);
  }
};

export const drawTextsToCanvas = (texts: TextState, canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');

  Object.values(texts).filter(Boolean).forEach((text) => {
    const { color, size, top, left, value, shadowColor, shadowSize, italic, strokeColor, strokeSize } = text;

    ctx.textBaseline = 'top';

    ctx.fillStyle = color;
    ctx.font = `${italic ? 'italic' : ''} ${size}px Arial sans-serif`;

    if (shadowSize) {
      ctx.shadowColor = shadowColor;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.shadowBlur = shadowSize;
    }

    ctx.fillText(value, left, top);
    if (strokeSize) {
      ctx.lineWidth = strokeSize;
      ctx.strokeStyle = strokeColor;
      ctx.strokeText(value, left, top);
    }
  });
};

export const drawStickersToCanvas = (stickers: StickerState, canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');

  Object.values(stickers).filter(Boolean).forEach((sticker) => {
    const { data, left, top, width, height } = sticker;

    const image = new Image();

    image.onload = () => {
      ctx.drawImage(image, left, top, width, height);
    }

    image.src = data;
  })
};

export const scaleCanvas = (source: HTMLCanvasElement, destination: HTMLCanvasElement, scale: number) => {
  destination.width = source.width * (scale / 100) + source.width;
  destination.height = source.height * (scale / 100) + source.height;
  const ctx = destination.getContext('2d');

  ctx.drawImage(source, 0, 0, source.width, source.height, 0, 0, destination.width, destination.height);
};

export const getScale = (sourceSize: number, destinationSize: number) => {
  return destinationSize > sourceSize
    ? 0
    : 100 * (destinationSize - sourceSize) / sourceSize;
};
