import { Reducer } from 'redux';
import update from 'immutability-helper';
import { CollageStore } from '../types';
import { SET_COLLAGE_IMAGES } from '../actions';

export const initialCollageState: CollageStore = {};

export const collageReducer: Reducer = (state = initialCollageState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_COLLAGE_IMAGES: {
      return update(state, {
        images: {
          $set: payload,
        }
      });
    }
    default:
      return state;
  }
};
