import React, { useEffect, useState } from 'react';
import { Button, Gapped, Select, Spinner } from '@skbkontur/react-ui';
import BorderAll from '@skbkontur/react-icons/BorderAll';
import { isEqual } from 'lodash';
import b_ from 'b_';
import FileReaderInput from 'react-file-reader-input';
import update from 'immutability-helper';

import { setErrorAction, setCollageImages, setImageAction, setApplyingStatus as disableMenu } from '../../../store/actions';
import { checkFileTypeImage } from '../utils/check-file-type';
import { collageEditorPresets } from './CollageEditor.presets';
import { CollageEditorPreset } from './CollageEditorPreset/CollageEditorPreset';
import { CollagePreset } from './CollageEditor.types';
import { DEFAULT_COLLAGE_SIZE, templates } from './CollageEditor.constants';


import './CollageEditor.css';
import { useDispatch } from 'react-redux';
import { DRAW_DELAY } from '../constants';

const b = b_.with('collage-editor');


const items = [
    Select.static(() => <Select.Item>Ничего не выбрано</Select.Item>),
    Select.SEP,
    ...Object.entries(templates)
];

export const CollageEditor: React.FC = () => {
    const [imagesCount, setImagesCount] = useState<number>();
    const [activePreset, setPreset] = useState<CollagePreset>();
    const [images, setImages] = useState<string[]>([]);
    const [applying, setApplying] = useState(false);
    const dispatch = useDispatch();

    const createOnChangeWithIndex = (imageIndex: number) => {
        return (_: React.ChangeEvent<HTMLInputElement>, results: FileReaderInput.Result[]) => {
            const fileReader = new FileReader();

            fileReader.onload = function(e) {
                setImages(update(images, {
                    [imageIndex]: {
                        $set: e.target.result as string,
                    },
                }));
            };
            const [, file] = results[0];

            if (checkFileTypeImage(file)) {
                fileReader.readAsDataURL(file);
            } else {
                dispatch(setErrorAction({ message: 'Файл не является изображением' }));
            }
        };
    }

    const getFileInputsByCount = () => {
        const inputs = new Array<JSX.Element>();

        for (let i = 0; i < templates[imagesCount]; i++) {
            inputs.push(
                <FileReaderInput key={i} onChange={createOnChangeWithIndex(i)}>
                    <Button>Загрузить изображение {i + 1}</Button>
                </FileReaderInput>
            );
        }

        return inputs;
    };

    const createCollage = () => {
        setApplying(true);
        dispatch(disableMenu(true));
        const abstractCanvas = document.createElement('canvas');

        const ctx = abstractCanvas.getContext('2d');

        activePreset.forEach((block, idx) => {
            const { x, y, width, height } = block;

            const top = 5 * y;
            const left = 5 * x;
            const blockWidth = width / 100 * DEFAULT_COLLAGE_SIZE;
            const blockHeight = height / 100 * DEFAULT_COLLAGE_SIZE;

            abstractCanvas.width = DEFAULT_COLLAGE_SIZE;
            abstractCanvas.height = DEFAULT_COLLAGE_SIZE;

            const img = new Image();

            img.onload = () => {
                if (blockWidth > blockHeight) {
                    const oldWidth = img.width;
                    img.width = blockWidth;
                    img.height = blockWidth / oldWidth * img.height;
                } else {
                    const oldHeight = img.height;
                    img.height = blockHeight;
                    img.width = blockHeight / oldHeight * img.width;
                }

                ctx.drawImage(img, left, top, img.width, img.height);
            };
            img.src = images[idx];
        });

        // хак, чтобы дождаться когда все картинки загрузтся и нарисуются. Нехорошо, но это точка роста
        setTimeout(() => {
          dispatch(setImageAction(abstractCanvas.toDataURL()));
          setApplying(false);
          dispatch(disableMenu(false));
        }, DRAW_DELAY);
    };

    useEffect(() => {
      return () => {
        dispatch(disableMenu(false));
      };
    }, []);

    useEffect(() => {
        dispatch(setCollageImages(images));
    }, [images]);

    const scalePercentFromImageSize = 60;

    return (
        <div>
            <Gapped gap={5} vertical>
                <BorderAll />
                <p className={b('label')}>Сколько изображений:</p>
                <Select items={items} value={imagesCount} onValueChange={setImagesCount} />
            </Gapped>
            {imagesCount && (
                <>
                    <h2 className={b('label')}>Пресеты:</h2>
                    <Gapped gap={10} vertical>
                        {collageEditorPresets[imagesCount]
                            .map((preset: CollagePreset, idx) =>
                                <CollageEditorPreset key={`${imagesCount}-${idx}`} preset={preset} onSelect={setPreset} isActive={isEqual(preset, activePreset)}/>)
                        }
                    </Gapped>
                </>
            )}
            {activePreset && (
                <>
                    <Gapped vertical gap={10}>
                       {getFileInputsByCount()}
                      <Button onClick={createCollage} disabled={templates[imagesCount] > images.length || applying}>{applying ? <Spinner type='mini' caption='Создаём' /> : 'Создать коллаж'}</Button>
                    </Gapped>

                    <CollageEditorPreset preset={activePreset} onSelect={setPreset} images={images} scalePercent={scalePercentFromImageSize} />
                </>
            )}

        </div>
    );
};
