import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import b_ from 'b_';
import { Button } from '@skbkontur/react-ui';
import { defaultCropState } from '../../../store/editor/editor';
import { CropState, IAppState } from '../../../store/types';
import { applyCrop } from '../../../store/actions';
import { ImageCropperProps } from './ImageCropper.types';
import { drawToCanvasFromBase64 } from '../../Canvas/helpers';

import './ImageCropper.css';

const b = b_.with('image-cropper');

const croppers = [
    {
        name: 'left', title: 'слева',
    },
    {
        name: 'top', title: 'сверху',
    },
    {
        name: 'right', title: 'справа',
    },
    {
        name: 'bottom', title: 'снизу',
    },
];

export const ImageCropper: React.FC<ImageCropperProps> = (props: ImageCropperProps) => {
  const { canvas } = props;
  const image = useSelector((state: IAppState) => state.editor.image);
    const [isCropping, setIsCropping] = useState(false);
    const [cropData, setCropData] = useState<CropState>(defaultCropState);

    const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      drawToCanvasFromBase64(canvas, image);
    }
  }, [image]);

    const toggleCropping = () => setIsCropping(!isCropping);

    const onSubmit = () => {
        toggleCropping();
        cropImage().then(() => dispatch(applyCrop(canvas.toDataURL())))
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        setCropData({ ...cropData, [name]: parseInt(value) });
    };

    const createCroppers = () => {
        return croppers.map(({ name, title }) => (
            <div className={b('cropper')} key={name}>
                <label className={b('cropper-label')}>{title}</label><input onChange={onChange} type='number' name={name} />px
            </div>
        ));
    };

    const cropImage = () => new Promise<void>((resolve) => {
      const ctx = canvas.getContext('2d');
      const { left, top, right, bottom } = cropData;

      const img = new Image();
      img.src = image;

      img.onload = () => {
        const newWidth = Math.max(img.width - left - right, 1);
        const newHeight = Math.max(img.height - top - bottom, 1);

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, left, top, newWidth, newHeight, 0, 0, newWidth, newHeight);
        resolve();
      };
    });

    return (
        <div>
            {!isCropping ? <Button onClick={toggleCropping}>Обрезать изображение</Button> :
            <>
                <label>Обрезать</label>
                {createCroppers()}
                <Button onClick={onSubmit}>Применить</Button>
            </>
            }
        </div>
    );
};
