import React, { useState, useEffect } from 'react';
import { Tabs } from '@skbkontur/react-ui';
import ToolsNavItem from './ToolsNavItem';
import { ToolsItems as tools } from './constants';
import { ToolsNavProps } from './types';
import { DRAW_DELAY } from '../../modules/constants';

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const ToolsNav: React.FC<ToolsNavProps> = (props: ToolsNavProps) => {
  const { className, value, onValueChange } = props;
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const timer = setTimeout(() => {
      forceUpdate();
    }, DRAW_DELAY);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Tabs
      className={className}
      vertical={true}
      value={value}
      onValueChange={(tab) => onValueChange(tab)}
      >
        {Object.keys(tools).map((key: string) => <ToolsNavItem id={key} title={tools[key]} key={key} />)}
    </Tabs>
  );
}

export default ToolsNav;
