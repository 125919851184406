import React from 'react';
import { Center } from '@skbkontur/react-ui';

const WelcomeScreen: React.FC = () => {
  return (
    <Center>
      <h2>Чтобы начать, загрузите картинку!</h2>
    </Center>
  );
};

export default WelcomeScreen;
