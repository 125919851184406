import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canvasRGBA } from 'stackblur-canvas';
import { Button, Gapped } from '@skbkontur/react-ui';
import { getCanvasFromBase64, drawToCanvasFromBase64, restoreCanvas } from '../../Canvas/helpers';
import { IAppState } from '../../../store/types';
import { BlurProps } from './types';
import { updateImage } from '../../../store/actions';

export const Blur: React.FC<BlurProps> = (props: BlurProps) => {
  const { canvas } = props;
  const image = useSelector((state: IAppState) => state.editor.image);
  const storeBlur = useSelector((state: IAppState) => state.editor.blur);
  const [currentBlur, setCurrentBlur] = useState(storeBlur);
  const [backupCanvas, setBackupCanvas] = useState<HTMLCanvasElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      drawToCanvasFromBase64(canvas, image);
      setBackupCanvas(getCanvasFromBase64(image));
    }
  }, [image]);

  const handleBlurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blur = Number(e.target.value) || 0;
    setCurrentBlur(blur);
    restoreCanvas(backupCanvas, canvas);
    canvasRGBA(canvas, 0, 0, canvas.width, canvas.height, blur);
  };

  const handleApplyButtonClick = () => {
    dispatch(updateImage(canvas.toDataURL()));
  };

  return (
    <Gapped vertical>
      <label htmlFor="blur">
        Размытие
        <input type="range" id="blur" name="blur" min="0" max="10" onChange={handleBlurChange} value={currentBlur}/>
        <input type="number" name="blur" min="0" max="10" onChange={handleBlurChange} value={currentBlur}/>
      </label>
      <Button onClick={handleApplyButtonClick}>Применить</Button>
    </Gapped>
  );
};
