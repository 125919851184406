import React, { useState } from 'react';
import b_ from 'b_';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/types';
import Header from './Header/Header';
import EditScreen from './EditScreen/EditScreen';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';
import { getFromLocalStorage } from '../Canvas/localStorageManager';

import './Layout.css';

const b = b_.with('layout');

export const Layout: React.FC = () => {
  const image = useSelector((state: IAppState) => state.editor.image);
  const localImage = getFromLocalStorage();
  const isImageLoaded = image !== null || localImage !== null;
  const [canvas, setCanvas] = useState<HTMLCanvasElement>(null);

  return (
    <div className={b('vertical-wrapper')}>
      <Header canvas={canvas} isImageLoaded={isImageLoaded} />
      {isImageLoaded
        ? <EditScreen canvas={canvas} onSetCanvas={setCanvas} />
        : <WelcomeScreen />
      }
    </div>
  );
};
