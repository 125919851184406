import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Gapped } from '@skbkontur/react-ui';
import { setColorFilter, updateImage } from '../../../store/actions';
import { IAppState } from '../../../store/types';
import { ColorFilterName, GRAYSCALE_MATRIX, SEPIA_MATRIX, COLORING_STEP, DEFAULT_COLOR_FILTER } from './const';
import { ColoringProps, Matrix33 } from './types';
import { drawToCanvasFromBase64, getCanvasFromBase64, restoreCanvas } from '../../Canvas/helpers';

export const Coloring: React.FC<ColoringProps> = (props: ColoringProps) => {
  const { canvas } = props;
  const image = useSelector((state: IAppState) => state.editor.image);
  const colorFilter = useSelector((state: IAppState) => state.editor.colorFilter);
  const [backupCanvas, setBackupCanvas] = useState<HTMLCanvasElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      setBackupCanvas(getCanvasFromBase64(image));
    }

    return (() => {
      dispatch(setColorFilter(DEFAULT_COLOR_FILTER));
    });
  }, []);

  useEffect(() => {
    if (image) {
      drawToCanvasFromBase64(canvas, image);
      setBackupCanvas(getCanvasFromBase64(image));
    }
  }, [image]);

  const getColoredImageData = (imgData: Uint8ClampedArray, matrix: Matrix33) => {
    const result = imgData.slice();

    for (let i = 0; i < result.length; i += COLORING_STEP) {
      const [r, g, b] = result.slice(i, i + 3);
      result[i] = Math.min(matrix[0][0] * r + matrix[0][1] * g + matrix[0][2] * b, 255);
      result[i + 1] = Math.min(matrix[1][0] * r + matrix[1][1] * g + matrix[1][2] * b, 255);
      result[i + 2] = Math.min(matrix[2][0] * r + matrix[2][1] * g + matrix[2][2] * b, 255);
    }

    return result;
  };

  const applyColorFilter = (canvas: HTMLCanvasElement, matrix: Matrix33) => {
    const ctxCopy = backupCanvas.getContext('2d');
    const imgData = ctxCopy.getImageData(0, 0, canvas.width, canvas.height);
    const data = getColoredImageData(imgData.data, matrix);
    imgData.data.set(data);

    const ctx = canvas.getContext('2d')
    ctx.putImageData(imgData, 0, 0);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorFilter = e.target.id;
    dispatch(setColorFilter(colorFilter));

    switch (colorFilter) {
      case 'grayscale':
        applyColorFilter(canvas, GRAYSCALE_MATRIX);
        return;
      case 'sepia':
        applyColorFilter(canvas, SEPIA_MATRIX);
        return;
      default:
        restoreCanvas(backupCanvas, canvas);
    }
  };

  const handleApplyButtonClick = () => {
    dispatch(updateImage(canvas.toDataURL()));
  };

  const colorFilterItems = () => {
    const result: React.ReactNode[] = [];

    for (const key in ColorFilterName) {
      result.push(
        <label htmlFor={key} key={key}>
          <input type="radio" id={key} name={key} onChange={handleFilterChange} checked={colorFilter === key} />
          {ColorFilterName[key]}
        </label>
      );
    }

    return (
      <Gapped vertical>
        {result}
      </Gapped>
    );
  };

  return (
    <Gapped vertical>
      {colorFilterItems()}
      <Button onClick={handleApplyButtonClick}>Применить</Button>
    </Gapped>
  );
};
