import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@skbkontur/react-ui/components/Button';
import { updateImage } from '../../../store/actions';
import { IAppState } from '../../../store/types';
import { drawToCanvasFromBase64, getCanvasFromBase64, scaleCanvas } from '../../Canvas/helpers';
import { Gapped } from '@skbkontur/react-ui/components/Gapped';

export const Scale: React.FC<{ canvas: HTMLCanvasElement; }> = ({ canvas }) => {
    const storeScale = useSelector((state: IAppState) => state.editor.scale || 0);
    const [currentScale, setCurrentScale] = useState(storeScale);
    const image = useSelector((state: IAppState) => state.editor.image);
    const [backupCanvas, setBackupCanvas] = useState<HTMLCanvasElement>(null);

    const dispatch = useDispatch();
    const onScaleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const scale = parseInt(value);

        scaleCanvas(backupCanvas, canvas, scale);

        setCurrentScale(scale);
    };

    const applyScaleToImage = () => {
        const resizedImage = canvas.toDataURL();

        dispatch(updateImage(resizedImage));
        setCurrentScale(0);
    };

    useEffect(() => {
        if (image) {
            drawToCanvasFromBase64(canvas, image);
            setBackupCanvas(getCanvasFromBase64(image));
        }
    }, [image]);

    return (
        <Gapped vertical>
          <label htmlFor="scale">
            Масштабирование
            <input type="range" name="scale" min="-100" max="100" onChange={onScaleChange} value={currentScale}/>
            <input type="number" name="scale" min="-100" max="100" onChange={onScaleChange} value={currentScale} />
          </label>
          <Button onClick={applyScaleToImage}>Применить</Button>
        </Gapped>
    );
}
