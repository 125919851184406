import { Matrix33 } from './types';

export const GRAYSCALE_MATRIX: Matrix33 = [
  [0.2126, 0.7152, 0.0722],
  [0.2126, 0.7152, 0.0722],
  [0.2126, 0.7152, 0.0722]
];

export const SEPIA_MATRIX: Matrix33 = [
  [0.393, 0.769, 0.189],
  [0.349, 0.686, 0.168],
  [0.272, 0.534, 0.131]
];

export const COLORING_STEP = 4;
export const DEFAULT_COLOR_FILTER = 'origin';
export const ColorFilterName: {[index: string]: string} = {
  origin: 'Оригинал',
  grayscale: 'Оттенки серого',
  sepia: 'Сепия',
};
