export type CanvasProps = {
  setCanvas: (canvas: HTMLCanvasElement) => void;
};

export enum DraggableItems {
  Text = 'text',
  Sticker = 'sticker',
}

export interface DragItem {
  type: DraggableItems
  id: string
  top: number
  left: number
}
