import { IAppState } from '../../store/types';

const AUTOSAVE_KEY = 'autosave:image-editor';

export const saveToLocalStorage = (data: Partial<IAppState>) => {
    localStorage.setItem(AUTOSAVE_KEY, JSON.stringify(data));
};

export const getFromLocalStorage = () => {
    const stringifiedData = localStorage.getItem(AUTOSAVE_KEY) || null;

    try {
        const { editor, original }: Partial<IAppState> = JSON.parse(stringifiedData);

        return { editor, original };
    } catch (e) {
        return {};
    }
};