import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Gapped, Select } from '@skbkontur/react-ui';
import { FrameProps } from './types';
import { DEFAULT_FRAME, GlobalCompositeOperation, IMAGE_SVG_XML, FRAMES } from './constants';
import { FrameTemplates } from './templates';
import { drawToCanvasFromBase64, getCanvasFromBase64, restoreCanvas } from '../../Canvas/helpers';
import { applyFrame } from '../../../store/actions';
import { IAppState } from '../../../store/types';

export const Frame: React.FC<FrameProps> = (props: FrameProps) => {
  const { canvas } = props;
  const image = useSelector((state: IAppState) => state.editor.image);
  const [backupCanvas, setBackupCanvas] = useState<HTMLCanvasElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      drawToCanvasFromBase64(canvas, image);
      setBackupCanvas(getCanvasFromBase64(image));
    }
  }, [image]);

  const handleApplyButtonClick = () => {
    dispatch(applyFrame(canvas.toDataURL()));
  };

  const handleValueChange = (value: string) => {
    restoreCanvas(backupCanvas, canvas);

    if (value === DEFAULT_FRAME) {
      return;
    }

    const template = FrameTemplates[value].template;

    const mask = document.createElement('img');
    const blob = new Blob([template], {type: IMAGE_SVG_XML});
    const url = URL.createObjectURL(blob);

    mask.onload = () => {
      const ctx = canvas.getContext('2d');
      const canvasAspectRatio = canvas.width / canvas.height;
      const maskAspectRatio = mask.width / mask.height;

      const [width, height] = canvasAspectRatio <= maskAspectRatio
        ? [canvas.width, canvas.width / maskAspectRatio]
        : [canvas.height * maskAspectRatio, canvas.height];

      const [top, left] = [(canvas.height - height) / 2, (canvas.width - width) / 2];

      ctx.globalCompositeOperation = GlobalCompositeOperation.DESTINATION_IN;
      ctx.drawImage(mask, left, top, width, height);

      // сбрасываем в значение по умолчанию
      ctx.globalCompositeOperation = GlobalCompositeOperation.SOURCE_OVER;
    };

    mask.src = url;
  };

  return (
    <div>
      <Gapped vertical>
        Выберите рамку
        <Select items={FRAMES} onValueChange={handleValueChange} defaultValue={DEFAULT_FRAME} />
        <Button onClick={handleApplyButtonClick}>Применить</Button>
      </Gapped>
    </div>
  );
};
