import React from 'react';
import b_ from 'b_';
import { ImageLoader, SaveImage } from '../../modules';
import { HeaderProps } from './types';

import './Header.css';

const b = b_.with('header');

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { canvas, isImageLoaded } = props;

  return (
    <header className={b('container')}>
      <div className={b('logo')}>
        <h1>Image Editor</h1>
      </div>
      <div className={b('nav')}>
        <div className={b('nav-load')}>
          <ImageLoader />
        </div>
        <div className={b('nav-save')}>
          {isImageLoaded && <SaveImage canvas={canvas} />}
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
