export const ToolsItems: {[index: string]: string} = {
  crop: 'Обрезка',
  scale: 'Масштабирование',
  rotate: 'Поворот',
  text: 'Текст',
  filters: 'Фильтры',
  collage: 'Коллаж',
  frames: 'Рамки',
  blur: 'Размытие',
  stickers: 'Стикеры',
  reset: 'Сброс всех изменений',
};

export const ToolsItemName = {
  CROP: 'crop',
  SCALE: 'scale',
  ROTATE: 'rotate',
  TEXT: 'text',
  FILTERS: 'filters',
  COLLAGE: 'collage',
  FRAMES: 'frames',
  BLUR: 'blur',
  STICKERS: 'stickers',
  RESET: 'reset',
}

export const TOOLTIP_POS = 'right middle';
export const TOOLTIP_TRIGGER = 'hover';
