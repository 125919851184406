import { Reducer } from 'redux';
import update from 'immutability-helper';
import { StatusStore } from '../types';
import { SET_APPLYING_STATUS } from '../actions';

export const initialStatusState: StatusStore = {
  applying: false,
};

export const statusReducer: Reducer = (state = initialStatusState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_APPLYING_STATUS: {
      return update(state, {
        applying: {
          $set: payload,
        },
      });
    }
    default:
      return state;
  }
};
