import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDrop, XYCoord, DropTargetMonitor } from 'react-dnd';
import b_ from 'b_'

import { saveToLocalStorage, getFromLocalStorage } from './localStorageManager';
import { CanvasProps, DraggableItems, DragItem } from './types';
import { IAppState } from '../../store/types';
import { setEditorState, setOriginal, updateImage, updateSticker, updateText } from '../../store/actions';
import { CanvasTexts } from './CanvasTexts/CanvasTexts';
import { CanvasStickers } from './CanvasStickers/CanvasStickers';

import './Canvas.css';

const b = b_.with('canvas');

export const Canvas: React.FC<CanvasProps> = (props: CanvasProps) => {
    const { setCanvas } = props;
    const dispatch = useDispatch();
    const [loadedFromLocalStorage, setLoadedFromLocalStorage] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const editorState = useSelector((state: IAppState) => state.editor);
    const originalState = useSelector((state: IAppState) => state.original);
    const { image, rotationAngle: angle, crop, colorFilter, blur, textsCount, texts, scale } = editorState;

    const setImageToCanvasContext = () => {
      if (canvasRef.current && image) {
        const ctx = canvasRef.current.getContext("2d");

        const img = new Image();

        img.onload = () => {
          canvasRef.current.width = img.width;
          canvasRef.current.height = img.height;
          ctx.drawImage(img, 0, 0);
        };

        img.src = image;
      }
    };

    useEffect(() => {
      if (
        canvasRef.current &&
        canvasRef.current.width === 0 &&
        canvasRef.current.width === 0
      ) {
        setImageToCanvasContext();
      }
    });

    useEffect(() => {
      setImageToCanvasContext();
    }, [image]);

    useEffect(() => {
      setCanvas(canvasRef.current);
    }, [canvasRef.current]);

    useEffect(() => {
      if (!loadedFromLocalStorage) {
        setLoadedFromLocalStorage(true);

        return;
      }

      saveToLocalStorage({ editor: editorState, original: originalState });
    }, [image, angle, blur, crop, colorFilter, textsCount, texts, loadedFromLocalStorage, scale]);

    useEffect(() => {
      const { editor: savedEditorState, original: savedOriginalState } = getFromLocalStorage();

      if (savedEditorState) {
        dispatch(updateImage(savedEditorState.image));
        dispatch(setEditorState(savedEditorState));
      }
      if (savedOriginalState) {
        dispatch(setOriginal(savedOriginalState.original));
      }
    }, []);

    const movable = (type: DraggableItems, id: string, top: number, left: number) => {
      switch (type) {
        case DraggableItems.Text:
          moveText(id, top, left);
          return;

        case DraggableItems.Sticker:
          moveSticker(id, top, left);
          return;
      }
    };

    const moveText = (id: string, top: number, left: number) => {
      dispatch(updateText(id, { top, left }));
    };

    const moveSticker = (id: string, top: number, left: number) => {
      dispatch(updateSticker(id, {top, left}));
    };

    const rerenderItem = (item: DragItem, monitor: DropTargetMonitor) => {
      requestAnimationFrame(() => {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        if (!delta) {
          return;
        }

        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);

        const leftWithBoundaries = Math.max(Math.min(left, canvasRef.current.width), 0);
        const topWithBoundaries = Math.max(Math.min(top, canvasRef.current.height), 0);

        movable(item.type, item.id, topWithBoundaries, leftWithBoundaries);
      });
    };

    const [, drop] = useDrop(() => ({
      accept: [DraggableItems.Text, DraggableItems.Sticker],
      drop: rerenderItem,
      hover: rerenderItem,
    }), [movable]);

    return (
      <div className={b('container')} ref={drop}>
        <CanvasTexts/>
        <CanvasStickers />
        <canvas ref={canvasRef}></canvas>
      </div>
    );
}
