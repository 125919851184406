import {
  EditorStore,
  ErrorData,
  CropState,
  TextItemState,
  TextStateUpdate,
  StickerItemState,
  StickerStateUpdate,
} from "./types";


export const setErrorAction = (errorData: ErrorData) => ({
    type: SET_ERROR,
    payload: errorData,
});

export const unsetErrorAction = () => ({
    type: UNSET_ERROR,
});


export const setImageAction = (image: string) => ({
    type: SET_IMAGE,
    payload: image,
});

export const setRotationAngleAction = (rotationAngle: number) => ({
    type: SET_ROTATION_ANGLE,
    payload: rotationAngle,
});

export const setColorFilter = (colorFilter: string) => ({
    type: SET_COLOR_FILTER,
    payload: colorFilter,
});

export const setBlurValue = (blur: number) => ({
    type: SET_BLUR_VALUE,
    payload: blur,
});

export const setEditorState = (data: EditorStore) => ({
    type: SET_EDITOR_STATE,
    payload: data,
});

export const setCropState = (cropState: CropState) => ({
    type: SET_CROP_STATE,
    payload: cropState,
});

export const resetFilters = (image: string) => ({
    type: RESET_FILTERS,
    payload: image,
});

export const addText = (text: TextItemState) => ({
    type: ADD_TEXT,
    payload: text,
});

export const updateText = (id: string, text: TextStateUpdate) => ({
    type: UPDATE_TEXT,
    payload: {
        id,
        text,
    },
});

export const deleteText = (id: string) => ({
    type: DELETE_TEXT,
    payload: id,
});

export const setCurrentText = (id: string) => ({
    type: SET_TEXT,
    payload: id,
});

export const resetText = () => ({
  type: RESET_TEXT,
})

export const applyFrame = (image: string) => ({
  type: APPLY_FRAME,
  payload: image,
});

export const applyRotation = (image: string) => ({
  type: APPLY_ROTATION,
  payload: image,
});

export const applyCrop = (image: string) => ({
  type: APPLY_CROP,
  payload: image,
});

export const updateImage = (image: string) => ({
  type: UPDATE_IMAGE,
  payload: image,
});

export const setOriginal = (image: string) => ({
  type: SET_ORIGINAL,
  payload: image,
});

export const setCollageImages = (images: string[]) => ({
    type: SET_COLLAGE_IMAGES,
    payload: images,
});

export const addSticker = (sticker: StickerItemState) => ({
  type: ADD_STICKER,
  payload: sticker,
});

export const deleteSticker = (id: string) => ({
  type: DELETE_STICKER,
  payload: id,
});

export const resetSticker = () => ({
  type: RESET_STICKER,
});

export const selectSticker = (id: string) => ({
  type: SELECT_STICKER,
  payload: id,
});

export const updateSticker = (id: string, sticker: StickerStateUpdate) => ({
  type: UPDATE_STICKER,
  payload: {
    id,
    sticker,
  },
});

export const setScale = (scaleCoef: number) => ({
  type: SET_SCALE,
  payload: scaleCoef,
});

export const setApplyingStatus = (status: boolean) => ({
  type: SET_APPLYING_STATUS,
  payload: status,
});

export const SET_EDITOR_STATE = 'SET_EDITOR_STATE';
export const SET_IMAGE = 'SET_IMAGE';
export const SET_ROTATION_ANGLE = 'SET_ROTATION_ANGLE';
export const SET_COLOR_FILTER = 'SET_COLOR_FILTER';
export const SET_BLUR_VALUE = 'SET_BLUR_VALUE';
export const SET_CROP_STATE = 'SET_CROP_STATE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_TEXT = 'ADD_TEXT';
export const UPDATE_TEXT = 'UPDATE_TEXT';
export const DELETE_TEXT = 'DELETE_TEXT';
export const SET_TEXT = 'SET_TEXT';
export const RESET_TEXT = 'RESET_TEXT';
export const APPLY_FRAME = 'APPLY_FRAME';
export const APPLY_ROTATION = 'APPLY_ROTATION';
export const APPLY_CROP = 'APPLY_CROP';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';

export const SET_SCALE = 'SET_SCALE';

export const SET_APPLYING_STATUS = 'SET_APPLYING_STATUS';

export const SET_COLLAGE_IMAGES = 'SET_COLLAGE_IMAGES';

export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';

export const SET_ORIGINAL = 'SET_ORIGINAL';

export const ADD_STICKER = 'ADD_STICKER';
export const DELETE_STICKER = 'DELETE_STICKER';
export const RESET_STICKER = 'RESET_STICKER';
export const SELECT_STICKER = 'SELECT_STICKER';
export const UPDATE_STICKER = 'UPDATE_STICKER';
