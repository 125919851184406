import React from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import b_ from 'b_';

import { deleteText, setCurrentText } from '../../../../store/actions';
import { IAppState } from '../../../../store/types';

import { DraggableItems } from '../../types';
import { emptyPixel } from '../../constants';
import { defaultFont } from './constants';
import { CanvasTextsItemProps } from './types';

import './CanvasTextsItem.css';

const b = b_.with('canvas-texts-item');

export const CanvasTextsItem: React.FC<CanvasTextsItemProps> = ({ id, value, top, left, color, size, strokeSize, strokeColor, italic, shadowSize, shadowColor }) => {
    const dispatch = useDispatch();
    const isCurrentText = useSelector<IAppState, boolean>(state => state.editor.currentText === id);
    const [, dragRef, preview] = useDrag(() => ({
        type: DraggableItems.Text,
        item: { id, top, left, type: DraggableItems.Text},
    }), [id, left, top]);

    return (
        <>
            <DragPreviewImage connect={preview} src={emptyPixel} />
            <div
                style={{
                    top,
                    left,
                    color,
                    font: `${size}px ${defaultFont} sans-serif `,
                    fontStyle: italic ? 'italic' : '',
                    textShadow: shadowSize && `2px 2px ${shadowSize}px ${shadowColor}`,
                    WebkitTextStroke: `${strokeSize}px ${strokeColor}`,
                }}
                onClick={() => dispatch(setCurrentText(id))}
                className={b('text', {
                    active: isCurrentText,
                })}
                ref={dragRef}
            >
                {value}
                {isCurrentText && <button className={b('delete')} onClick={() => dispatch(deleteText(id))}>
                    <img src='/svg/close.svg' alt='Delete' width='5' height='5' />
                </button>}
            </div>
        </>
    );
};
