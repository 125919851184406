import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { editorReducer } from './editor/editor';
import { errorReducer } from './error/error';
import { originalReducer } from './original/original';
import { collageReducer} from './collage/collage';
import { stickerReducer } from './stickers/stickers';
import { statusReducer } from './status/status';
import { IAppState } from './types';

const rootReducer = combineReducers<IAppState>({
    editor: editorReducer,
    error: errorReducer,
    original: originalReducer,
    collage: collageReducer,
    sticker: stickerReducer,
    status: statusReducer,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware()));
