import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@skbkontur/react-ui';
import { resetFilters } from '../../../store/actions'
import { IAppState } from '../../../store/types';
import { ResetFiltersProps } from './ResetFilters.types';
import { drawToCanvasFromBase64 } from '../../Canvas/helpers';

export const ResetFilters: React.FC<ResetFiltersProps> = (props: ResetFiltersProps) => {
  const { canvas } = props;
  const originalImage = useSelector((state: IAppState) => state.original.original);
  const image = useSelector((state: IAppState) => state.editor.image);

  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      drawToCanvasFromBase64(canvas, image);
    }
  }, [image]);

  const handleResetFiltersClick = () => {
    dispatch(resetFilters(originalImage));
    drawToCanvasFromBase64(canvas, originalImage);
  };

  return (
    <Button onClick={handleResetFiltersClick}>
      Сбросить фильтры
    </Button>
  );
};

