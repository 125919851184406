import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import b_ from 'b_';
import { Button } from '@skbkontur/react-ui';
import { addText, resetText, setCurrentText, updateImage, updateText } from '../../../store/actions';
import { IAppState, TextItemState } from '../../../store/types';
import { drawTextsToCanvas, drawToCanvasFromBase64 } from '../../Canvas/helpers';
import { defaultText, PLACEHOLDER, MAX_SHADOW_SIZE, MAX_STROKE_SIZE, MAX_TEXT_SIZE } from './constants';
import { TextEditorProps } from './types';
import { getCount } from '../helpers';

import './TextEditor.css'

const b = b_.with('text-editor');

export const TextEditor: React.FC<TextEditorProps> = (props: TextEditorProps) => {
    const { canvas } = props;
    const dispatch = useDispatch();
    const [textData, setTextData] = useState(defaultText);
    const image = useSelector((state: IAppState) => state.editor.image);
    const texts = useSelector((state: IAppState) => state.editor.texts);
    const currentText = useSelector<IAppState, { id?: string; data?: TextItemState }>(state => {
        const id = state.editor.currentText;

        if (!id) {
            return {};
        }

        return { id, data: state.editor.texts[id] };
    });
    const isDisable = getCount(texts) === 0;

    useEffect(() => {
      return (() => {
        dispatch(resetText());
      });
    }, []);

    useEffect(() => {
      if (image) {
        drawToCanvasFromBase64(canvas, image);
      }
    }, [image]);

    useEffect(() => {
        if (currentText.data) {
            setTextData(currentText.data);
        }
    }, [currentText.data]);

    const createNewText = () => {
        dispatch(setCurrentText(null));
        setTextData(defaultText);
    };

    const addNewText = () => {
        dispatch(addText(textData));
        createNewText();
    };

    const handleApplyButtonClick = () => {
      drawTextsToCanvas(texts, canvas);
      dispatch(resetText());
      dispatch(updateImage(canvas.toDataURL()));
    };

    const onChange: ChangeEventHandler<HTMLInputElement> = e => {
        const { name, type } = e.target;
        const value = type === 'checkbox' ? e.target.checked : e.target.value;

        if (currentText.id) {
            const { id, data } = currentText;
            dispatch(updateText(id, { ...data, [name]: value }));
        } else {
            setTextData({ ...textData, [name]: value });
        }
    };

    return (
        <div className={b('controls')}>
          <div className={b('controls-item')}>Добавить текст</div>
          <input className={b('controls-item')} name='value' type='text' value={textData.value} onChange={onChange} placeholder={PLACEHOLDER} />
          <div className={b('controls-item', { 'with-label': true })}>
            <label className={b('label')}>Цвет</label>
            <input name='color' type='color' value={textData.color} onChange={onChange} className={b('color-picker-input')} />
          </div>
          <div className={b('controls-item', { 'with-label': true })}>
            <label className={b('label')}>Размер</label>
            <input className={b('number-control')} name='size' max={MAX_TEXT_SIZE} type="number" value={textData.size} onChange={onChange} /> px
          </div>
          <div className={b('controls-item', { 'with-label': true })}>
            <label className={b('label')}>Стиль текста</label>
            <div>
              <input id='italic' type='checkbox' name='italic' className={b('radio')} checked={textData.italic} onChange={onChange} />
              <label htmlFor="italic"><img className={b('radio-icon')} src='/svg/italic.svg' width='15' height='15' /></label>
            </div>
          </div>
          <div className={b('controls-item', { 'with-label': true })}>
            <label htmlFor="stroke">Обводка</label>
            <input className={b('number-control')} type='number' name='strokeSize' max={MAX_STROKE_SIZE} value={textData.strokeSize} onChange={onChange} />
            <input name='strokeColor' type='color' value={textData.strokeColor} onChange={onChange} className={b('color-picker-input')} />
          </div>
          <div className={b('controls-item', { 'with-label': true })}>
            <label className={b('label')}>Тень</label>
            <input className={b('number-control')} name='shadowSize' max={MAX_SHADOW_SIZE} type="number" value={textData.shadowSize} onChange={onChange} /> px
            <input name='shadowColor' type='color' value={textData.shadowColor} onChange={onChange} className={b('color-picker-input')} />
          </div>
          {currentText.id && <Button className={b('controls-item')} onClick={createNewText}>Создать новый</Button>}
          {!currentText.id && <Button className={b('controls-item')} onClick={addNewText} disabled={textData.value.length === 0}>Добавить</Button>}
          <Button onClick={handleApplyButtonClick} disabled={isDisable}>Применить</Button>
        </div>
    );
};
