import { Reducer } from 'redux';
import update from 'immutability-helper';
import { StickerStore } from '../types';
import {
  ADD_STICKER,
  DELETE_STICKER,
  RESET_STICKER,
  SELECT_STICKER,
  UPDATE_STICKER
} from '../actions';

export const initialStickerState: StickerStore = {
  stickers: {},
  stickersCount: 0,
  selectedId: null,
};

export const stickerReducer: Reducer = (state = initialStickerState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ADD_STICKER: {
      const stickersCount = state.stickersCount + 1;

      return update(state, {
        stickersCount: {
          $set: stickersCount,
        },
        stickers: {
          [stickersCount]: {
            $set: payload,
          }
        },
      });
    }

    case DELETE_STICKER: {
      return update(state, {
        stickers: {
          [payload]: {
            $set: undefined,
          },
        },
        selectedId: {
          $set: null,
        },
      });
    }

    case RESET_STICKER: {
      return initialStickerState;
    }

    case SELECT_STICKER: {
      return update(state, {
        selectedId: {
          $set: payload,
        },
      });
    }

    case UPDATE_STICKER: {
      const { id, sticker } = payload;

      return update(state, {
        stickers: {
          [id]: {
            $merge: sticker,
          },
        },
      });
    }

    default:
      return state;
  }
};
