export const defaultText = {
    value: '',
    top: 0,
    left: 0,
    color: '#000000',
    size: 15,
    strokeSize: 0,
    strokeColor: '#ffffff',
    italic: false,
    shadowSize: 0,
    shadowColor: '#000000',
};

export const PLACEHOLDER = 'Напиши что-нибудь...';

export const MAX_TEXT_SIZE = 128;
export const MAX_SHADOW_SIZE = 10;
export const MAX_STROKE_SIZE = 10;
