import React from 'react';
import { useDispatch } from 'react-redux';
import { addSticker } from '../../../store/actions';
import { StickerItemState } from '../../../store/types';
import { STICKER_WIDTH } from './constants';
import { StickerItemProps } from './types';

const defaultSticker: StickerItemState = {
  data: '',
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export const StickerItem: React.FC<StickerItemProps> = (props: StickerItemProps) => {
  const { sticker } = props;
  const stickerSrc = require(`./svg/${sticker}.svg`).default;

  const dispatch = useDispatch();

  const handleStickerDoubleClick = (e) => {
    const stickerData = Object.assign(defaultSticker, {
      data: e.target.src,
      width: e.target.width,
      height: e.target.height,
    });

    dispatch(addSticker(stickerData));
  };

  return (
    <div>
      <img src={stickerSrc} alt={sticker} width={STICKER_WIDTH} onDoubleClick={handleStickerDoubleClick}/>
    </div>
  );
}
