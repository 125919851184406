import { FrameTemplatesType } from './types';

export const FrameTemplates: FrameTemplatesType = {
  circle: {
    name: `Круг`,
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
      <circle r="50%" cx="50%" cy="50%" fill="#fff" />
    </svg>`,
  },

  star5: {
    name: `Пятиконечная звезда`,
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="136" height="126">
      <polygon points="70,5 90,41 136,48 103,80 111,126 70,105 29,126 36,80 5,48 48,41" fill="#fff" />
    </svg>`,
  },

  heart: {
    name: `Сердечко`,
    template: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      height="315" width="342" >
      <defs>
        <g id="heart">
        <path
          d="M0 200 v-200 h200
          a100,100 90 0,1 0,200
          a100,100 90 0,1 -200,0
          z" />
        </g>
      </defs>
        <use xlink:href="#heart" class="outline " fill="#fff" transform="rotate(225,150,121)" />
    </svg>`,
  },

  horizontalEllipse: {
    name: `Горизонтальный эллипс`,
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="80">
      <ellipse rx="50" ry="40" cx="50" cy="40" fill="#fff" />
    </svg>`,
  },

  verticalEllipse: {
    name: `Вертикальный эллипс`,
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="100">
      <ellipse rx="40" ry="50" cx="40" cy="50" fill="#fff" />
    </svg>`,
  },
};
