import { Reducer } from 'redux';
import { OriginalStore } from '../types';
import { SET_ORIGINAL } from '../actions';

const initialOriginalState: OriginalStore = {
  original: null,
};

export const originalReducer: Reducer = (state = initialOriginalState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ORIGINAL:
      return { ...initialOriginalState, original: payload };
    default:
      return state;
  }
};
