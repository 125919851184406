import React from 'react';
import { Dropdown, MenuItem } from '@skbkontur/react-ui';
import { FILENAME, JPEG_FORMAT } from './constants';
import { SaveImageProps } from './types';

export const SaveImage: React.FC<SaveImageProps> = (props: SaveImageProps) => {
  const saveImage = (format?: string) => {
    const { canvas } = props;

    // Сохраняем через <a href='canvas.toDataURL' download='<filename>'/>
    const anchorElement = document.createElement('a');
    anchorElement.href = canvas.toDataURL(format);
    anchorElement.download = format === JPEG_FORMAT ? `${FILENAME}.jpg` : `${FILENAME}.png`;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  };

  return (
    <Dropdown caption="Сохранить как">
      <MenuItem onClick={() => saveImage()}>PNG</MenuItem>
      <MenuItem onClick={() => saveImage(JPEG_FORMAT)}>JPG</MenuItem>
    </Dropdown>
  );
}
