import { Reducer } from 'redux';
import { ErrorStore } from '../types';
import { SET_ERROR, UNSET_ERROR } from '../actions';

export const initialErrorState: ErrorStore = {
  isError: false,
  errorData: null,
};

export const errorReducer: Reducer = (state = initialErrorState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ERROR:
      return { ...state, isError: true, errorData: payload };
    case UNSET_ERROR:
      return { ...state, isError: false, errorData: null };
    default:
      return state;
  }
};
