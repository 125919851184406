import React, { useState } from 'react';
import b_ from 'b_';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Center } from '@skbkontur/react-ui';
import { Canvas } from  '../../Canvas/Canvas';
import ToolsNav from '../Tools/ToolsNav';
import ToolItem from '../Tools/ToolItem';
import { EditScreenProps } from './types';

import '../Layout.css';

const b = b_.with('layout');

const EditScreen: React.FC<EditScreenProps> = (props: EditScreenProps) => {
  const { canvas, onSetCanvas } = props;
  const [activeTab, setActiveTab] = useState('filters');

  return (
    <div className={b('content-wrapper')}>
      <ToolsNav className={b('tools')} value={activeTab} onValueChange={setActiveTab} />
      <ToolItem canvas={canvas} tool={activeTab} />

      <Center className={b('canvas-wrapper')}>
        <DndProvider backend={HTML5Backend}>
          <Canvas setCanvas={onSetCanvas} />
        </DndProvider>
      </Center>
    </div>
  );
};

export default EditScreen;
