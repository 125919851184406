import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState, StickerState } from '../../../store/types';
import { CanvasStickerItem } from './CanvasStickerItem';

export const CanvasStickers: React.FC = () => {
  const stickers = useSelector<IAppState, StickerState>(state => state.sticker.stickers);

  const getStickersItems = () =>
    Object.keys(stickers).filter(id => stickers[id]).map(id => {
        const sticker = stickers[id];

        return <CanvasStickerItem id={id} {...sticker} key={id} />;
      });

  return (
    <>
      {getStickersItems()}
    </>
  );
};
