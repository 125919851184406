import { Reducer } from 'redux';
import update from 'immutability-helper';
import { CropState, EditorStore } from '../types';
import {
  SET_IMAGE,
  SET_ROTATION_ANGLE,
  SET_COLOR_FILTER,
  SET_BLUR_VALUE,
  SET_EDITOR_STATE,
  SET_CROP_STATE,
  RESET_FILTERS,
  ADD_TEXT,
  UPDATE_TEXT,
  DELETE_TEXT,
  SET_TEXT,
  APPLY_FRAME,
  APPLY_ROTATION,
  APPLY_CROP,
  RESET_TEXT,
  UPDATE_IMAGE,
  SET_SCALE,
} from '../actions';

export const defaultCropState: CropState = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

const initialEditorState: EditorStore = {
  image: null,
  rotationAngle: 0,
  colorFilter: `origin`,
  blur: 0,
  crop: defaultCropState,
  texts: {},
  textsCount: 0,
  currentText: null,
  scale: 0,
};

export const editorReducer: Reducer = (state = initialEditorState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_EDITOR_STATE:
      return { ...initialEditorState, ...payload };
    case SET_IMAGE:
      return { ...initialEditorState, image: payload };
    case SET_ROTATION_ANGLE:
      return { ...state, rotationAngle: payload };
    case SET_COLOR_FILTER:
      return { ...state, colorFilter: payload };
    case SET_BLUR_VALUE:
      return { ...state, blur: payload };
    case SET_CROP_STATE:
      return { ...state, crop: payload };
    case RESET_FILTERS:
      return { ...initialEditorState, image: payload };
    case ADD_TEXT: {
      const textsCount = state.textsCount + 1;

      return update(state, {
        textsCount: {
          $set: textsCount,
        },
        texts: {
          [textsCount]: {
            $set: payload,
          },
        },
      });
    }
    case UPDATE_TEXT: {
      const { id, text } = payload;

      return update(state, {
        texts: {
          [id]: {
            $merge: text,
          },
        },
      });
    }
    case DELETE_TEXT: {
      return update(state, {
        texts: {
          [payload]: {
            $set: undefined,
          },
        },
        currentText: {
          $set: null,
        },
      });
    }
    case SET_TEXT: {
      return update(state, {
        currentText: {
          $set: payload,
        },
      });
    }
    case RESET_TEXT: {
      return update(state, {
        texts: {
          $set: {},
        },
        currentText: {
          $set: null,
        },
      })
    }
    case APPLY_FRAME: {
      return update(state, {
        image: {
          $set: payload,
        },
      });
    }
    case APPLY_ROTATION: {
      return update(state, {
        image: {
          $set: payload,
        },
      });
    }
    case APPLY_CROP: {
      return update(state, {
        image: {
          $set: payload,
        },
      });
    }
    case UPDATE_IMAGE: {
      return update(state, {
        image: {
          $set: payload,
        },
      });
    }
    case SET_SCALE: {
      return update(state, {
        scale: {
          $set: payload,
        },
      });
    }
    default:
      return state;
  }
};
