import React from 'react';
import { useSelector } from 'react-redux';

import { CanvasTextsItem } from './CanvasTextsItem/CanvasTextsItem';
import { IAppState, TextState } from '../../../store/types';

export const CanvasTexts: React.FC = () => {
    const texts = useSelector<IAppState, TextState>(state => state.editor.texts);

    const getTextsItems = () =>
        Object.keys(texts).filter(id => texts[id]).map(id => {
            const textData = texts[id];

            return <CanvasTextsItem id={id} {...textData} key={id} />;
        });

    return (
        <>
            {getTextsItems()}
        </>
    );
};
