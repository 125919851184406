import React from 'react';
import b_ from 'b_';
import { ScrollContainer, Loader } from '@skbkontur/react-ui';
import { ImageCropper, ImageRotator, Coloring, Blur, ResetFilters, TextEditor, Frame, CollageEditor, Sticker, Scale } from '../../modules';
import { ToolItemProps } from './types';
import { ToolsItemName } from './constants';

import '../Layout.css';

const b = b_.with('layout');


const ToolItem: React.FC<ToolItemProps> = (props: ToolItemProps) => {
  const { canvas, tool } = props;

  const renderActiveTool = () => {
    switch (tool) {
      case ToolsItemName.BLUR:
        return <Blur canvas={canvas} />;
      case ToolsItemName.COLLAGE:
        return <CollageEditor />;
      case ToolsItemName.CROP:
        return <ImageCropper canvas={canvas} />;
      case ToolsItemName.FILTERS:
        return <Coloring canvas={canvas} />;
      case ToolsItemName.FRAMES:
        return <Frame canvas={canvas} />;
      case ToolsItemName.RESET:
        return <ResetFilters canvas={canvas} />;
      case ToolsItemName.ROTATE:
        return <ImageRotator canvas={canvas} />;
      case ToolsItemName.STICKERS:
        return <Sticker canvas={canvas} />;
      case ToolsItemName.TEXT:
        return <TextEditor canvas={canvas} />;
      case ToolsItemName.SCALE:
        return <Scale canvas={canvas} />
    }
  };

  return (
    !canvas
      ? <Loader type="normal" active/>
      : <ScrollContainer className={b('scroll-container')}>
          {renderActiveTool()}
      </ScrollContainer>
  );
};

export default ToolItem;
